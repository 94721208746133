//home top
$header-1-bg-color: $secondary-color;
$header-1-font-color: $color-off-white;
$header-1-heading-color: $color-off-white;
$header-1-button-color: $primary-color;
$header-1-button-font-color: $color-misc;
// Apply styles to all themes with specific button colors
.header-1{
  img {
    border-radius: 0;
  }

  // Nesting button styles within theme classes
  .button {
    // Assuming default button background, hover, and font color are set globally
    @include button($expand: true);
    border-radius: 15px !important;
    //font-size: 0.5rem !important;
  }
  .button {
    @include button($expand: true, $background: $header-1-button-color, $background-hover: scale-color($header-1-button-color, $alpha: -30%), $color: $header-1-button-font-color, $style: solid);        
  }
    
  background-color: $header-1-bg-color;
  color: $header-1-font-color;
  
  h1, h2, h3, h4, h5, h6 {
    color: $header-1-heading-color;
  }
    
  

}


//home featured items, catering, locations, contact
$theme-1-bg-color: $dark-gray;
$theme-1-font-color: $white;
$theme-1-heading-color: $primary-color;
$theme-1-button-color: $primary-color;
$theme-1-button-font-color: $color-misc;

//menu
$theme-2-bg-color: $dark-gray !important;
$theme-2-font-color: $secondary-color !important;
$theme-2-heading-color: $primary-color !important;
$theme-2-button-color: $primary-color !important;
$theme-2-button-font-color: $color-misc !important;

$theme-3-bg-color: $color-dark;
$theme-3-font-color: $white;
$theme-3-heading-color: $primary-color;
$theme-3-button-color: $color-misc;
$theme-3-button-font-color: $primary-color;

//footer
$theme-4-bg-color: $color-dark;
$theme-4-font-color: $color-light;
$theme-4-heading-color: $white;
$theme-4-button-color: $primary-color;
$theme-4-button-font-color: $color-misc;

//halloween
$theme-5-bg-color: #f39a24;
$theme-5-font-color: #4c1b4a;
$theme-5-heading-color: #FFFFFF;
$theme-5-button-color: #FFFFFF;
$theme-5-button-font-color: $black;

$theme-5-callout-bg-color: #e27111;

$theme-5-card-bg-color: #e27111;
$theme-5-card-border-color: rgba($black, 0.25);
$theme-5-card-font-color: $black;
$theme-5-divider-bg-color: #e27111;
$theme-5-divider-font-color: #FFFFFF;

//halloween 2
$theme-6-bg-color: #4c1b4a;
$theme-6-font-color: #f39a24;
$theme-6-heading-color: $white;
$theme-6-button-color: #4c1b4a;
$theme-6-button-font-color: #f39a24;

//global button
  .button {
    //@include button($expand: true);
    //border-radius: 80px !important;
    //flex-grow: 0; 
  }

// Apply styles to all themes with specific button colors
.theme-1, .theme-2, .theme-3, .theme-4 {
  img {
    //border-radius: $global-radius;
  }

  h1, h2, h3, h4, h5, h6 {
    // Heading styles can be nested here if specific to themes
  }

  .card .button {
    //@include button($expand: true, $background: $theme-2-button-color, $color: $theme-2-button-font-color, $style: solid);
    //border-radius: 0 !important;
    //font-size: 1.0rem !important;      
    /* Prevent stretching within flex environments */
    flex-grow: 0;      
  }
}


// Specific theme adjustments for additional properties
.theme-1 {
  background-color: $theme-1-bg-color;
  color: $theme-1-font-color;
  
  h1, h2, h3, h4, h5, h6 {
    color: $theme-1-heading-color;
  }
    
  .button {
    @include button($expand: true, $background: $theme-1-button-color, $color: $theme-1-button-font-color, $style: solid);
  }    

}

.theme-2 {
  background-color: $theme-2-bg-color;
  color: $theme-2-font-color;
  
  h1, h2, h3, h4, h5, h6 {
    color: $theme-2-heading-color;
  }

  .button {
    @include button($expand: true, $background: $theme-2-button-color, $color: $theme-2-button-font-color, $style: solid);
    flex-grow: 0;   
  }
    .card {
        background-color: #FFFFFF;
    }
}

.theme-3 {
  background-color: $theme-3-bg-color;
  color: $theme-3-font-color;
  
  h1, h2, h3, h4, h5, h6 {
    color: $theme-3-heading-color;
  }

  .button {
    @include button($expand: true, $background: $theme-3-button-color, $color: $theme-3-button-font-color, $style: solid);
    flex-grow: 0;   
  }

}

.theme-4 {
  background-color: $theme-4-bg-color;
  color: $theme-4-font-color;
  
  h1, h2, h3, h4, h5, h6 {
    color: $theme-4-heading-color;
  }
    
  .button {
    @include button($expand: true, $background: $theme-4-button-color, $color: $theme-4-button-font-color, $style: solid);    
  }    
    
}

.theme-5 {
  background-color: $theme-5-bg-color;
  color: $theme-5-font-color;
  
    h1, h2, h3, h4, h5, h6 {
      color: $theme-5-heading-color;
    }

    a {
        color: $color-misc;  
        
    }
    
    .button {
      @include button(
          $expand: false, 
          $background: $theme-5-button-color,
          $background-hover: scale-color($theme-5-button-color, $lightness: -15%), // Adjust hover color
          $color: $theme-5-button-font-color, 
          $style: solid);
    }    
    .callout {
        background-color: $theme-5-callout-bg-color;
    }
    
    .card {
        border: 1px solid $theme-5-card-border-color;
        border-radius: 0;
        background: $theme-5-card-bg-color;
        box-shadow: none;
        color: $theme-5-font-color;
    }
    .card-section {
        padding: 1rem;
    }    
    .card-divider {
        padding: 1rem;
        background: $theme-5-divider-bg-color;
        color: $theme-5-divider-font-color;
    }
}

.theme-6 {
  background-color: $theme-6-bg-color;
  color: $theme-6-font-color;
  
  h1, h2, h3, h4, h5, h6 {
    color: $theme-6-heading-color;
  }
   
    a {
        color: $black;  
        
    }
    
  .button {
    @include button($expand: true, $background: $theme-6-button-color, $color: $theme-6-button-font-color, $style: solid);
  }    
    
}

