/*====================================================================*\
     																												     =colors
\*====================================================================*/


html{
//height: 100%;
}
body {
 background-color: $color-beige;
}

/*====================================================================*\
     																												   =features
\*====================================================================*/

.featured-list {
	display: none;
}

/*====================================================================*\
     																												   =fx
\*====================================================================*/

.shad01 {
box-shadow: rgba(0, 0, 0, 0.25) 4px 4px 2.8px;
}
.shad02 {
box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
}
/*====================================================================*\
   																													=full width
\*====================================================================*/
	img.fw {
    width: 100%;
    max-width: 100%;
    height: auto;
    border-width: 0;
    vertical-align: middle;
    -ms-interpolation-mode: bicubic;
}

.fullimg img {
    position: relative;
    display: block;
    height: auto;
    width: 100%;
    border: 0;
    max-width: 100%;
    height: auto;
    border-width: 0;
    vertical-align: middle;
    -ms-interpolation-mode: bicubic;
}
/*====================================================================*\
   																											          =fonts
\*====================================================================*/

.f-red {
		color: #C41F3A;
}
.f-yellow {
		color: #F4D37D;
}
.f-black {
		color: #000000;
}
.f-white {
		color: #FFFFFF;
}
.f-gray {
		color: #d6d6d6;
}

h1,h2,h3,h4,h5,h6 {
padding-top: 0;
margin-top: 0;
}


@font-face {
    font-family: 'Brothers';
    src: url('../fonts/Brothers-Bold.eot');
    src: url('../fonts/Brothers-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Brothers-Bold.woff2') format('woff2'),
        url('../fonts/Brothers-Bold.woff') format('woff'),
        url('../fonts/Brothers-Bold.ttf') format('truetype'),
        url('../fonts/Brothers-Bold.svg#Brothers-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

h1,h2,h3,h4,h5,h6 {
	font-family: 'Brothers', san-serif;
}
/*====================================================================*\
   																											        =spacing
\*====================================================================*/

.padding-4 {
  @include padding(4, 4, 4, 4);
}

.responsive-embed, .flex-video {
    position: relative;
    height: 0;
    margin-bottom: 1rem;
    padding-bottom: 0;
    overflow: hidden;
}
/*====================================================================*\
   																											  =bg and themes
\*====================================================================*/

.bg-red {
		background-color: $color-red;
}
.bg-black {
		background-color: $color-black;
}
.bg-white {
		background-color: $color-white;
}
.bg-gray {
		background-color: $color-gray;
}
.bg-beige {
		background-color: $color-beige;
}
.bg-beigel {
		background-color: $color-beigel;
}
.bg-beigexl {
		background-color: $color-beigexl;
}
.bg-white {
		background-color: $color-white;
}
.bg-paper {
	background-image:url(../img/paper.jpg);
}
.events-burn {
	background-image:url(../img/burn.gif);
	background-repeat: no-repeat;
	background-size: cover;
}

.events-home {
	color: $color-black;
	
		h1,h2,h3,h4,h5,h6 {
		color: $color-black;
		}

		a:active, a:hover, a:link, a:visited {
		color: $color-black;
		}
		.red-link:active, .red-link:hover, .red-link:link, .red-link:visited {
		color: $color-red !important; 
		}
		.button {
			background-color: $color-red;
			color: $color-white !important;
		}

		.button {
			display: inline-block;
			vertical-align: middle;
			margin: 0 0 1rem 0;
			padding: 0.85em 1em;
			border: 1px solid transparent;
			border-radius: 0;
			-webkit-transition: background-color 0.25s ease-out,color 0.25s ease-out;
			transition: background-color 0.25s ease-out,color 0.25s ease-out;
			font-size: 0.9rem;
			-webkit-appearance: none;
			line-height: 1;
			text-align: center;
			cursor: pointer;
		}
	
}



.announce {
	color: $color-black;
	
		h1,h2,h3,h4,h5,h6 {
		color: $color-black;
		}

		a:active, a:hover, a:link, a:visited {
		color: $color-red;
		}
		.red-link:active, .red-link:hover, .red-link:link, .red-link:visited {
		color: $color-red !important; 
		}
		.button {
			background-color: $color-red;
			color: $color-white !important;
		}
		.button {
			display: inline-block;
			vertical-align: middle;
			margin: 0 0 1rem 0;
			padding: 0.85em 1em;
			border: 1px solid transparent;
			border-radius: 0;
			-webkit-transition: background-color 0.25s ease-out,color 0.25s ease-out;
			transition: background-color 0.25s ease-out,color 0.25s ease-out;
			font-size: 0.9rem;
			-webkit-appearance: none;
			line-height: 1;
			text-align: center;
			cursor: pointer;
		}
	
}




.footer {
	color: #6b6b6b;
	background-color: $color-black;
	
		h1,h2,h3,h4,h5,h6 {
		color: $color-red;
		}

		a:active, a:hover, a:link, a:visited {
		color: #6b6b6b;
		}
}



/*====================================================================*\
   																											  =media queries
\*====================================================================*/
  // Only affects xlarge screens and larger
  @include breakpoint(large) {
			#logo {
			//	z-index: 99999;
			//	position: relative; 	
			//	bottom: -70px;
			//	margin-top: -70px;
				height: 80px;

			}	

}
// Only affects medium screens and smaller
  @include breakpoint(xlarge down) {


		
}
  // Only affects small screens, not small or large
  @include breakpoint(small only) {
		#logo {
		 // z-index: 99999;
		//	position: relative; 	
		//	bottom: -40px;
		//	margin-top: -40px;
		height: 40px;

		}
		
}
  // Only affects medium screens, not small or large
  @include breakpoint(medium only) {
		#logo {
		//	z-index: 99999;
		//	position: relative; 	
		//	bottom: -40px;
		//	margin-top: -40px;
		//	height: 60px;
		height: 40px;			

		}
		
}
  // Only affects medium screens and larger
  @include breakpoint(medium up) {
		.r-banner {
			min-height: 627px;
		}
			#logo {
			//	z-index: 99999;
			//	position: relative; 	
			//	bottom: -70px;
			//	margin-top: -70px;
			//	height: 100px;

			}		
		
}
/*====================================================================*\
   																											          =VIDEO
\*====================================================================*/

.responsive-embed {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */

}
.responsive-embed iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/*====================================================================*\
     																												     =slider
\*====================================================================*/

.slick-slider {
  margin: 0 auto;
  max-width: auto;
  max-height: 100%;
  visibility: hidden; //hide until loaded
}
.slick-slider.slick-initialized { 
  visibility: visible; //show when loaded
}
    .slick-slide img {
      max-width: auto;
      max-height: 100%;
      margin: 0 auto; // center dif size slides
    }

    .slider-events {
      width: 100%;
      margin: 0 auto;
    }

    .slick-list {
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    .slick-slide {
      display: inline-block;
      margin: 0px 0px;
    }

    .slick-prev:before,
    .slick-next:before {
      color: $primary-color !important;
      font-size: 3rem !important;
    }

    .slick-slide {
      transition: all ease-in-out .3s;
      opacity: .2;
    }

    .slick-active {
      opacity: .5;
    }

    .slick-current {
      opacity: 1;
    }

  .slick-prev, .slick-next {
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }

  .slick-prev {
    left: 20px;
  }

  .slick-next {
    right: 20px;
  }
    


/*====================================================================*\
   																											            =NAV
\*====================================================================*/

$nav-main-bg: #000000;
$nav-link-color: #FFFFFF;
$nav-hover-color-anim: #FFFFFF;
$nav-fa-color: #FFFFFF;
$nav-drop-bg: #000000;
$nav-mobile-link-color: #FFFFFF;
$nav-ecommerce-bg: #3A3A3A;
$ecommerce-link-color: #FFFFFF;

.logo-list li{
	padding: 0px, 20px, 20px, 20px;
}

.dropdown.menu.medium-horizontal>li.is-dropdown-submenu-parent>a::after {
    border-color: #000000 transparent transparent;
}

.order-b {
	color: #000000 !important; 
	background-color: #C41F3A;
}
.order-b {
	color: #FFFFFF !important; 
	background-color: #C41F3A;
}

.off-canvas.is-transition-push {
    z-index: 9999 !important;
}
.dropdown.menu>li.is-active>a {
	color:#FFFFFF;
}
.dropdown.menu.medium-horizontal>li.is-dropdown-submenu-parent>a::after { // dropdown triangle
    //display: block;
    //width: 0;
    //height: 0;
   // border: inset 6px;
   // content: '';
   // border-bottom-width: 0;
   // border-top-style: solid;
    border-color: #FFFFFF transparent transparent;
   // right: 5px;
  //  left: auto;
   // margin-top: -3px;
}

.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle)>a::after { // mobiledropdown triangle
   // display: block;
   // width: 0;
   // height: 0;
   // border: inset 6px;
   // content: '';
   // border-bottom-width: 0;
   // border-top-style: solid;
    border-color: #FFFFFF transparent transparent;
   // position: absolute;
   // top: 50%;
   // margin-top: -3px;
  //  right: 1rem;
}



.off-canvas {
  z-index: 99999 !important;
}

.dropdown {
	z-index: 99999 !important;
}

.off-canvas.is-transition-push {
    z-index: 99999 !important; // puts mobile bg above owl slider
}

.ecommerce-header {
  width: 100%;
  background-color: $nav-main-bg; //main nav bg
  padding: 10px 20px 10px 20px;

  .logo {
    height: 50px;
   // width: 160px;
  }

  ul {
    // background-color: $medium-gray;
  }

  li {
    display: inline-block;
  }

  .nav-links {
    margin-left: 15px;
  }

  a {
    color: $nav-link-color;
    transition: color 0.5s ease;
    display: block;
  }

  a:hover {
    color: lighten($nav-hover-color-anim, 25%); // hover color animation 
    transition: color 0.5s ease;
  }

  input {
    margin-right: 0px;

    @include breakpoint(large down) {
      width: 150px;
    }
  }

  &.top-bar {
    padding-top: 0;
    padding-bottom: 0;

  }

  .button.search-button {
    width: 39px;
    height: 39px;
    background-size: 20px 20px;
    background-image: url("https://placehold.it/20x20");
    background-repeat: no-repeat;
    background-position: center center;
    padding-left: 0;
    padding-right: 0;
    border-radius: 0px;
  }

  li {
		
    // padding: 1em 0.85em 1em 0.85em;
  }	
  .menu > .main-nav-link > a {
		//font-size: 1.2rem;
   // padding: 1em 0.85em 1em 0.85em;
  }
	.is-dropdown-submenu-item {
		// padding: 1em 0.85em 1em 0.85em;
	}
	
}

.is-dropdown-submenu {
    //position: absolute;
   // top: 0;
    //left: 100%;
    z-index: 9999 !important;
    //display: none;
    //min-width: 200px;
    border: none;
    background: $nav-drop-bg;
}

.dropdown.menu>li.is-dropdown-submenu-parent>a {
    position: relative;
    //padding-right: 1.5rem;
		//font-size: 1.2rem;
}

.ecommerce-header-top {
  width: 100%;
  background-color: $nav-ecommerce-bg; // high small bar bg color
  height: 40px;
  padding: 10px 20px 10px 20px;

  .fa {
    color: $secondary-color;
    font-size: 1.2rem;
  }
}

.ecommerce-header-top-message {
  color: lighten(#FFFFFF, 100%);
  float: left;
  a {
    color: lighten(#FFFFFF, 100%); // high small bar links
    transition: color 0.5s ease;
  }

  a:hover {
    color: lighten(#FFFFFF, 100%);
    transition: color 0.5s ease;
  }	
	
}

.ecommerce-header-top-links {
  color: lighten($ecommerce-link-color, 100%); // high small bar font

  ul li {
    display: inline;
    padding-left: 20px;
  }

  a {
    color: lighten(#FFFFFF, 100%); // high small bar links
    transition: color 0.5s ease;
  }

  a:hover {
    color: lighten(#FFFFFF, 100%);
    transition: color 0.5s ease;
  }
}

.ecommerce-header-off-canvas { 
  background-color: #000000; // mobile full vertical bg
	
  .menu { // increased z-index blocks mobile close button
    padding: 1rem 0;
  }

  .menu a {
    color: $nav-mobile-link-color;
  }
	.close-button:hover, .close-button:focus {
	color: #FFFFFF;
  z-index: 99999 !important;
}

.close-button {
	color: #FFFFFF;
  z-index: 99999 !important;
}	

}

.ecommerce-header-mobile {
  background-color: #000000; // mobile nav bg
  display: flex;
  padding: rem-calc(10) rem-calc(20);
  align-items: center;
  justify-content: space-between;
}

.ecommerce-header-mobile-left,
.ecommerce-header-mobile-right,
.ecommerce-header-mobile-center {
  flex: 1 0 0;
}

.ecommerce-header-mobile-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.ecommerce-header-mobile-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ecommerce-header-mobile-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .fa {
    color: $nav-fa-color;
    font-size: 1.5rem;
  }
}

.ecommerce-header-search-exandable input[type=search] {
  margin: 0 1rem;
  background: $light-gray url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat 9px center;
  border: solid 1px $medium-gray;
  padding: 9px 10px 9px 26px;
  width: 35px;
  border-radius: 5000px;
  transition: all 0.5s;
}

.ecommerce-header-search-exandable input[type=search]:focus {
  width: 120px;
  background-color: $white;
  border-color: $primary-color;
  box-shadow: 0 0 5px rgba($primary-color, 0.5);
}

.ecommerce-header-search-exandable input:-moz-placeholder {
  color: $dark-gray;
}

.ecommerce-header-search-exandable input::-webkit-input-placeholder {
  color: $dark-gray;
}

.ecommerce-header-search-exandable input[type=search]:hover {
  background-color: $white;
}

